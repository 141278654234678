import React, { useEffect, useRef, useState } from 'react';
import { parseData } from '../helpers/news';
import SEO from '../components/seo';
import Article, { Section } from '../components/article';
import PageTitle from '../components/page-title';
import { News } from '../components/news';
import { RenderNewsCard } from '../components/cards';
import HighlightedWidget, {
  withHighlightedNews,
} from '../components/news-widget';
import Button from '../components/button';

const BASE_NEWS_NUMBER = 30;

export default function Noticias(props) {
  const {
    pageContext: { posts },
  } = props;

  const newsRef = useRef();

  const nonHiddenNews = posts.filter((post) => post.postData.hidden !== true);
  const news = nonHiddenNews ? parseData(nonHiddenNews) : [];

  const [showNews, setShowNews] = useState(BASE_NEWS_NUMBER);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (newsRef.current) {
      setIsLoading(!(newsRef.current.childElementCount === showNews));
    }
  }, [showNews]);

  return (
    <>
      <SEO title="Notícias" />
      <Article as="section">
        <PageTitle>Notícias</PageTitle>
        <Section>
          <h2>Destaques</h2>
          <HighlightedWidget HOC={withHighlightedNews} />
        </Section>
        <Section>
          <h2>Últimas Notícias</h2>
          <News ref={newsRef}>
            {news.slice(0, showNews).map((item) => (
              <li key={item.id}>
                <RenderNewsCard item={item} />
              </li>
            ))}
          </News>
          <Button
            style={{ margin: 'auto', display: 'block' }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              if (news.length - 1 > showNews + 30) {
                setShowNews(showNews + 30);
              } else {
                setShowNews(news.length - 1);
              }
            }}
          >
            Mostrar mais
          </Button>
        </Section>
      </Article>
    </>
  );
}
